import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSuspense, useController } from '@rest-hooks/react';

import ProductResource from 'resources/organization/ProductResource';
import EventResource from 'resources/organization/EventResource';
import OrganizationResource from 'resources/organization/OrganizationResource';

import { defaultOffer, offerCategory } from 'utils/offersFormHelper';
import { convertPriceFromCents } from 'utils/rewards';
import { priceErrors } from 'utils/pricingsConsts';
import { useError } from 'utils/useErrorController';
import { scrollToTop } from 'utils/scrollToTop';
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';

import FormSection from 'ui/FormSection';
import Label from 'ui/text/Label';

import Link from './Form/Link';
import SupplyLimit from './Form/SupplyLimit';
import RedeemLimit from './Form/RedeemLimit';
import NotificationList from './Form/NotificationList';
import SettingsVisibility from './Form/SettingsVisibility';
import Title from './Form/Title';
import Description from './Form/Description';
import ExtendedDescription from './Form/ExtendedDescription';
import CategorySelector from './Form/CategorySelector';
import Media from './Form/Media';
import Footer from './Form/Footer';
import NewProductModal from './NewProductModal';
import Price from './Form/Price';

import TagSelector from 'components/TagSelector';
import MarkdownEditor from 'components/MarkdownEditor';
import EndDate from 'components/DateInputField';
import SeoOptions from 'components/SeoOptions';

import { handleValidation } from './handleValidation';
import { createFormData } from './createFormData';
import VisibilitySelector from './Form/VisibilitySelector';

const OffersForm = ({ record={} }) => {

    const { fetch } = useController();
    const history = useHistory();
    const params  = useParams();

    const { handleError, loading, setLoading, validate } = useError();
    const { setOpen, setMessage, setSeverity } = useToast();

    const organizationId = parseInt(params.organizationId);

    const hasRecord = Object.keys(record).length > 0;

    const searchParams = new URLSearchParams(window.location.search);
    const eventId = searchParams.get('eventId');

    const events = useSuspense(EventResource.list(), {organization_id: organizationId, upcoming: true});
    const organization = useSuspense(OrganizationResource.detail(), {id: organizationId});

    const {currency, application_fee_enabled} = organization;

    const initialEventId = hasRecord && record.event_id ? record.event_id : eventId ? eventId : null;
    const initialEventTitle = hasRecord && record.event_title ? record.event_title : eventId ? events.find(item => item.id === +eventId).title : null;
    const initialCategory = hasRecord && record.category ? record.category : eventId ? offerCategory.ticket.value : offerCategory.merch.value;
    const initialFreeValue = hasRecord ? !record.unit_price : false;
    const initialVisibleValue = hasRecord ? record.visible : eventId ? 'always' : 'available';

    const initialOffer = { ...defaultOffer,
                           ...record,
                           unit_price: convertPriceFromCents(record.unit_price),
                           application_fee: convertPriceFromCents(record.application_fee),
                           currency: currency,
                           category: initialCategory,
                           event_id: initialEventId,
                           event_title: initialEventTitle,
                           free: initialFreeValue,
                           visible: initialVisibleValue
                        };

    const [offer, setOffer] = useState(initialOffer);
    const [errors, setErrors] = useState(priceErrors);
    const [openCustomModal, setOpenCustomModal] = useState(null);

    const handleChange = ({target: {name, value}}) => {
        let newOffer = { ...offer, [name]: value };
        setOffer(newOffer);
    };

    const handleCheckBoxChange = ({target: {checked, name}}) => {
        let newOffer = { ...offer, [name]: checked };
        setOffer(newOffer);
    };

    const handleSwitch = ({target: {checked}}) => {
        let newOffer = { ...offer, status: checked ? 'active' : 'inactive' };
        setOffer(newOffer);
    };

    const request = record.id ? ProductResource.update() : ProductResource.create();
    const requestBody = record.id ?  {organization_id: organizationId, id: offer.id} : {organization_id: organizationId};

    const handleSubmit = useCallback(
        async (e, offer) => {
          try {
            e.preventDefault();
            validate(offer, handleValidation);

            const formData = createFormData(offer);
            const {id} = await fetch(request, requestBody, formData);

            handleSuccessfulResponse(id);
          } catch (error) {
            handleError(error)
          }
        },
        [fetch],
    );

    const handleSuccessfulResponse = async (id) => {
        await fetch(ProductResource.detail(), { id: id, organization_id: organizationId });
        await fetch(ProductResource.list(), {organization_id: organizationId, active: true});
        await fetch(ProductResource.list(), {organization_id: organizationId});
        await fetch(EventResource.list(), {organization_id: organizationId, upcoming: true});
        setLoading(false);

        if (record.id) {
            customToast('success', `${offer.category} updated successfully`, setOpen, setSeverity, setMessage);
            handleRedirect();
        } else {
            setOpenCustomModal(true);
        }
    };

    const handleRedirect = () => {
        history.push(`/organizations/${organizationId}/${offer?.category === 'ticket' ? 'events' : 'perks'}`);
    };

    const handleCloseCustomModal = () => {
        setOpenCustomModal(false);
        setOffer({...defaultOffer,
                    category: offer.category,
                    event_id: initialEventId,
                    event_title: initialEventTitle,
                    tag_ids: []
                });
        setErrors(priceErrors);
        scrollToTop();
    };

    const {id, status, title, description, slug, category, redeem_limit, supply_limit, show_end_date, show_supply, visible, notification_list, content_text_link, event_title} = offer;

    const isTicket = category === offerCategory.ticket.value;
    const formTitle = isTicket ? "Ticket Details for " + event_title : "Product Details";

    return (
        <>
            <form onSubmit={ (e) => handleSubmit(e, offer) }>
            <FormSection title={formTitle}>
                <Title
                    title={title}
                    onChange={handleChange}
                    object={offer}
                    onObjectChange={setOffer}
                    isTicket={isTicket}
                    />

                <Description
                    description={description}
                    onChange={handleChange}
                    isTicket={isTicket}
                    />

                { !isTicket &&
                <>
                    <ExtendedDescription
                        object={offer}
                        onObjectChange={setOffer} />

                    <div className='row'>
                        <CategorySelector
                            category={category}
                            onChange={handleChange} />

                        <TagSelector
                            object={offer}
                            onObjectChange={setOffer}
                            objectType={"product"}
                            organizationId={organizationId} />
                    </div>
                </>
                }

            </FormSection>

            {!isTicket &&
            <FormSection title="Images">
                <Media
                    offer={ offer }
                    onOfferChange={ setOffer }
                    loading={ loading }
                    setLoading={ setLoading }
                    onError={ handleError } />
            </FormSection>
            }

            <Price
                errors={errors}
                onErrorsChange={setErrors}
                offer={offer}
                onOfferChange={setOffer}
                onChange={handleChange}
                currency={currency}
                application_fee_enabled={application_fee_enabled} />

            <FormSection title={"Availability"}>

                <VisibilitySelector
                    visible={visible}
                    onChange={handleChange} />

                <div className={"row"}>
                    <SupplyLimit
                        supply_limit={supply_limit}
                        onChange={handleChange} />

                    <RedeemLimit
                        redeem_limit={redeem_limit}
                        onChange={handleChange} />
                </div>

                <EndDate
                    attribute={'end_date'}
                    object={offer}
                    onChange={setOffer}
                    label={"Expiration date. Leave empty for no expiration"} />

                <SettingsVisibility
                    show_end_date={show_end_date}
                    show_supply={show_supply}
                    onChange={handleCheckBoxChange} />

            </FormSection>

            {!isTicket &&
                <SeoOptions
                    slug={slug}
                    onChange={handleChange} />
            }

            {/* to reset MarkdownEditor need to force component re-render by changing expandable */}
            <FormSection title={"After Purchase"} expandable={!openCustomModal}>
                <MarkdownEditor
                    object={offer}
                    setObject={setOffer}
                    attribute={"content_text"}
                    placeholder={"Confirmation Message"}
                    height={"200px"}
                />

                <Label secondary padded>
                    This message will be displayed to the member upon purchasing this product. This is where you can:<br />
                    - Outline the next steps the member should take.<br />
                    - Specify the expected timeframe for any feedback or follow-up.<br />
                    - If applicable, share private discount codes or unique instructions.<br />
                </Label>

                <Link
                    content_text_link={content_text_link}
                    onChange={ handleChange } />

                <NotificationList
                    notification_list={notification_list}
                    onChange={handleChange} />
            </FormSection>

            <Footer
                id={ id }
                status={ status }
                onChange={ handleSwitch }
                loading={ loading }
                isTicket={ isTicket }
                />
        </form>

        {openCustomModal &&
            <NewProductModal
                open={!!openCustomModal}
                title={offer.title}
                onClose={handleRedirect}
                done={handleCloseCustomModal}
                category={offer.category}
                /> }
        </>
    );
};

OffersForm.propTypes = {
    record: PropTypes.object,
};

export default OffersForm;
