import React from 'react';
import PropTypes from 'prop-types';

import DateInputField from 'components/DateInputField';
import MarkdownEditor from 'components/MarkdownEditor';

import FormSection from 'ui/FormSection';

import { slugify } from 'utils/slugify';

import { TextField } from '@mui/material';

const Details = ({event, onEventChange, onChange, setUnsavedChanges}) => {

    const {title, location, capacity, single} = event;

    const dateFields = [
        { attribute: 'start_date', label: 'Start Date *' },
        { attribute: 'end_date', label: 'End Date' },
    ]

  return (
    <FormSection title={"Event Info"}>
        <TextField
            fullWidth
            size='small'
            color='warning'
            name={"title"}
            label={"Title *"}
            value={title}
            onChange={onChange}
            onBlur={() => slugify(event, onEventChange)}
        />

        {single &&
            <div className='date-fields'>
                {dateFields.map((field, index) => (
                    <DateInputField
                        key={index}
                        attribute={field.attribute}
                        label={field.label}
                        object={event}
                        onChange={onEventChange}
                        setUnsavedChanges={setUnsavedChanges}
                        disablePast={true}
                    />
                ))}
            </div>
        }

        <TextField
            fullWidth
            size='small'
            color='warning'
            name={"location"}
            label={"Location"}
            value={location}
            onChange={onChange}
        />

        <TextField
            fullWidth
            size='small'
            color='warning'
            name={"capacity"}
            label={"Capacity. Leave empty for unlimited"}
            value={capacity}
            onChange={onChange}
        />

        {!single &&
            <MarkdownEditor 
                object={event}
                setObject={onEventChange}
                attribute={"description"}
                placeholder={'Description'}
                allowImageUpload={true}
                allowEmbeds={true}
                setUnsavedChanges={setUnsavedChanges}
            />}

    </FormSection>
  );
};

Details.propTypes = {
    event: PropTypes.object,
    onEventChange: PropTypes.func,
    onChange: PropTypes.func,
    setUnsavedChanges: PropTypes.func
};

export default Details;
