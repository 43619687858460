import React, { useState, useEffect } from "react";
import { useController, useSuspense } from "@rest-hooks/react";
import PropTypes from 'prop-types';

import CheckInModalContent from "./CheckInModalContent";
import TicketFilter from "./TicketFilter";
import Header from "./Header";
import Status from "./Status";
import QRCodeScanner from "./QRCodeScanner";
import NewTicketModal from "./NewTicketModal.js";

import DataSelectionTable from "components/DataTable/DataSelectionTable";
import ConfirmModal from "components/ConfirmModal.js";
import EmailEditor from "components/EmailEditor";
import EmailConfirmModalContent from "components/EmailConfirmModalContent";
import TabPanels from "components/TabPanels";
import CustomModal from "components/CustomModal";
import QRCodeGenerator from "components/QRCodeGenerator";

import Button from "ui/Button";

import { useError } from "utils/useErrorController";
import { useToast } from "utils/context/ToastContext";
import customToast from "utils/customToast";
import { ticketsColumns } from "utils/tables/columns/ticketsColumns";
import { useEmailController } from "utils/useEmailController";
import usePaginationAndFilteringController from "utils/usePaginationAndFilteringController";

import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

const TicketsList = ({TicketResource, ticketResourceParams, event}) => {

  const [confirmModal, setConfirmModal] = useState(false);
  const [customModalContent, setCustomModalContent] = useState(null);
  const [openNewTicketModal, setOpenNewTicketModal] = useState(false);

  const { fetch } = useController();
  const { handleError, setLoading } = useError();
  const { setOpen, setMessage, setSeverity } = useToast();

  //fetch tickets without pagination to be able to find matching ticket after qr code read and status tab
  const tickets = useSuspense(TicketResource.list(), ticketResourceParams);

  const {
    email,
    setEmail,
    editingEmail,
    handleEmailSendRequest,
    handleEmailSend,
    handleEditEmailClick,
    handleEmailCancel,
  } = useEmailController(
    event.organization_id,
    setConfirmModal,
    event.id
  );

  const {
    objectState,
    fetchData,
    handleFilterChange,
    handleQueryChange,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: TicketResource,
      fetchDataParams: ticketResourceParams,
  })

  //fetchTickets on mount
  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckIn = async (ticket_id) => {
    try {
      setLoading(true);
      //repeat ticketResourceParams 
      //params are expected in the request url by the organization and in the request body by the checkin namespace controller
      await fetch(TicketResource.update(), { id: ticket_id, ...ticketResourceParams}, ticketResourceParams);
      setLoading(false);
      customToast( "success", "Check In Successful", setOpen, setSeverity, setMessage );
      fetchData();
      setCustomModalContent(null);
    } catch (error) {
      handleError(error);
    }
  };

  const handleOpenTicketDetails = (ticket) => {
    setCustomModalContent({
      title: ticket.title,
      content: <CheckInModalContent ticket={ticket} />,
      button: (
        <Button
          disabled={ticket.completed_at}
          highlighted
          icon={QrCodeScannerIcon}
          onClick={() => handleCheckIn(ticket.id)}
        >
          Check In
        </Button>
      ),
    });
  };

  const onQRCodeRead = (result) => {
    const newSelectedTicket = tickets.find((ticket) => ticket.uuid === result);
    if (newSelectedTicket) {
      handleOpenTicketDetails(newSelectedTicket);
    } else {
      handleRetry(result);
    }
  };

  const handleRetry = async (result) => {
    try {
      const updatedTicketList = await fetch(TicketResource.list(), ticketResourceParams);
      const newSelectedTicket = updatedTicketList.find((ticket) => ticket.uuid === result);

      if (newSelectedTicket) {
        handleOpenTicketDetails(newSelectedTicket);
      } else {
        handleError("Ticket not found");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleOpenQRCodeScanner = () => {
    setCustomModalContent({
      title: "Check In",
      content: <QRCodeScanner onQRCodeRead={onQRCodeRead} />,
      button: (
        <Button secondary onClick={() => setCustomModalContent(null)}>
          Cancel
        </Button>
      ),
    });
  };

  const handleOpenQRCodeModal = () => {
    setCustomModalContent({
      title: event.title,
      content: (
        <QRCodeGenerator
          url={`${event.external_url}?openTicket=1`}
          image_url={event.organization_logo_url}
        />
      ),
    });
  };

  return (
    <>
      <TabPanels labels={["Tickets", "Status"]}>
        <>
          <Header
            editingEmail={editingEmail}
            onEditingEmailChange={handleEditEmailClick}
            onEmailCancel={handleEmailCancel}
            onCheckIn={handleOpenQRCodeScanner}
            onShareEvent={handleOpenQRCodeModal}
            onAddTicket={() => setOpenNewTicketModal(true)}
            addTicketDisabled={event.tickets.length === 0}
          />

          {editingEmail && (
            <EmailEditor
              object={email}
              onObjectChange={setEmail}
              onEmailSendRequest={handleEmailSendRequest}
              onEmailCancel={handleEmailCancel}
              onError={handleError}
              autoSelect={true}
            />
          )}

          <DataSelectionTable
            records={objectState.filteredObjects}

            columns={ticketsColumns(
              handleOpenTicketDetails
            )}

            searchLabel="Search by Ticket ref, Order or Member"
            onQueryChange={handleQueryChange}
            queryString={objectState.query}

            serverPaginationProps={{
              paginationServer: true,
              paginationTotalRows: objectState.totalCount,
              page: objectState.page,
              onChangePage: handlePageChange
            }}

            filter={
              <TicketFilter
                onChange={handleFilterChange}
              />
            }
          />
        </>

        <div>
          <Status tickets={tickets} />
        </div>
      </TabPanels>

      {customModalContent && (
        <CustomModal
          handleClose={() => setCustomModalContent(null)}
          customModalContent={customModalContent}
        />
      )}

      {openNewTicketModal && (
        <NewTicketModal
          tickets={event?.tickets}
          onClose={() => setOpenNewTicketModal(false)}
          open={openNewTicketModal}
          done={fetchData}
          TicketResource={TicketResource}
          ticketResourceParams={ticketResourceParams}
        />
      )}

      {confirmModal && (
        <ConfirmModal
          title={"Send email?"}
          open={confirmModal}
          setOpen={setConfirmModal}
          onConfirm={handleEmailSend}
        >
          {
            <EmailConfirmModalContent
              content={email?.content}
              subject={email?.subject}
              ticketCount={tickets?.length}
            />
          }
        </ConfirmModal>
      )}
    </>
  );
};

TicketsList.propTypes = {
  TicketResource: PropTypes.object,
  ticketResourceParams: PropTypes.object,
  event: PropTypes.object,
};

export default TicketsList;
