import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from 'react-router-dom';

import EventResource from 'resources/organization/EventResource';
import CheckinTokenResource from 'resources/organization/CheckinTokenResource';

import ConfirmModal from 'components/ConfirmModal';

import ActionButtons from './ActionButtons';
import CheckinCredentialsModal from './CheckinCredentialsModal';

import Button from 'ui/Button';
import CustomChip from 'ui/CustomChip';
import ListComponent from 'ui/List/ListComponent';
import ListItemComponent from 'ui/List/ListItemComponent';
import ListItemTextComponent from 'ui/List/ListItemTextComponent';
import SplitButton from 'ui/SplitButton';

import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import { useError } from 'utils/useErrorController';
import isMobileDevice from 'utils/mobileAndTabletCheck';

import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';

import moment from 'moment';

const EventsList = ({upcoming = false, past = false}) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const [openCheckinCredentialsModal, setOpenCheckinCredentialsModal] = useState(false);
    const [checkinCredentials, setCheckinCredentials] = useState({});

    const { fetch } = useController();
    const history = useHistory();
    const params  = useParams();

    const organizationId = params.organizationId;
    const requestParams = {organization_id: organizationId};
    if (upcoming) requestParams.upcoming = true
    if (past) requestParams.past = true

    const data = useSuspense(EventResource.list(), requestParams);
    const { setOpen, setMessage, setSeverity } = useToast();
    const { handleError, loading, setLoading } = useError();

    const handleDelete = async () => {
        try {
            setLoading(true);
            await fetch(EventResource.delete(), {organization_id: organizationId, id: selectedEvent.id});
            await fetch(EventResource.list(), {organization_id: organizationId, upcoming: true});
            await fetch(EventResource.list(), {organization_id: organizationId});
            customToast('success', `Event Deleted`, setOpen, setSeverity, setMessage);
            setSelectedEvent(null);
            setLoading(false);
        } catch (error) {
            handleError(error)
        }
    };

    const handleConfirmDeleteModal = (event) => {
        setSelectedEvent(event);
        setConfirmDelete(true);
    };

    const isMobile = isMobileDevice();
    
    const renderCustomChip = (status) => {
        if (status && !isMobile) {
            return <CustomChip label={status} minwidth padded />
        }
    };

    const renderCheckInButton = (event) => {
        const eventDate = moment(event.start_date).utc().startOf('day');
        const today = moment().utc().startOf('day');

        return eventDate.isSame(today) && event.has_ticket
    };

    const generateCheckInCredentials = async () => {
        try {
            setLoading(true);
            const {checkin_url, secret_token} = await fetch(CheckinTokenResource.create(), {organization_id: organizationId}, {event_id: selectedEvent.id});
            setCheckinCredentials({checkin_url, secret_token});
            setLoading(false);
        } catch (error) {
            handleError(error)
        }
    };

    const handleCheckinCredentialsModalOpen = (event) => {
        setSelectedEvent(event);
        setCheckinCredentials({
            checkin_url: event.checkin_url,
            secret_token: event.checkin_secret_token
        });
        setOpenCheckinCredentialsModal(true);
    };

    const handleCheckinCredentialsModalClose = () => {
        setOpenCheckinCredentialsModal(false);
        setSelectedEvent(null);
    };

    const MainButton = () =>
        <Button icon={AddCircleOutlineIcon} highlighted fullwidth onClick={() => history.push(`/organizations/${organizationId}/events/new`)} loading={loading}>
            Add Single Event
        </Button>;

    const buttonOptions = [
        {label: 'Add Recurring Event', onClick: () => history.push(`/organizations/${organizationId}/events/new?recurring=true`), icon: <ControlPointDuplicateIcon/>},
    ];

    return (
        <div className='org-event-list'>
            <ListComponent>

                <SplitButton MainButton={MainButton} options={buttonOptions} disabled={loading} />

                {data.map((event, index) => (
                    <ListItemComponent
                        key={index + 1} >

                        <ListItemTextComponent text={event.title} />

                        {renderCustomChip(event.status)}
                        
                        {renderCheckInButton(event) &&
                            <Button 
                                small 
                                className={"chip-like"}
                                icon={LibraryAddOutlinedIcon}
                                onClick={() => history.push(`/organizations/${organizationId}/events/${event.id}/tickets`)} disabled={loading}>
                                    Check In
                            </Button>
                        }

                        <ActionButtons
                            event={event} 
                            loading={loading} 
                            organizationId={organizationId} 
                            history={history} 
                            handleConfirmDeleteModal={handleConfirmDeleteModal}
                            renderCheckInButton={renderCheckInButton(event)}
                            openCheckinCredentialsModal={handleCheckinCredentialsModalOpen}
                            />

                    </ListItemComponent>
                ))}
            </ListComponent>

            {openCheckinCredentialsModal &&
                <CheckinCredentialsModal 
                    open={openCheckinCredentialsModal}
                    loading={loading}
                    onClose={handleCheckinCredentialsModalClose}
                    onGenerate={generateCheckInCredentials}
                    checkinCredentials={checkinCredentials}
                />}

            <ConfirmModal
                    title={`Deleting event`}
                    open={confirmDelete}
                    setOpen={setConfirmDelete}
                    onConfirm={handleDelete}
                >
                    You are about to delete an event. Would you like to continue?
            </ConfirmModal>

        </div>
    )
};

EventsList.propTypes = {
    upcoming: PropTypes.bool,
    past: PropTypes.bool
};

export default EventsList;