const labels = {
    en:{
        testUnusedKey: "Please do not translate me. I am just a test key",
        testEnOnlyKey: "Please do not translate me. I am just a test key",
        literal:{
            sending: "Sending...",
            signUp: "Sign up",
            and_sc: "and",
            reset: "Reset",
            signIn: "Sign in",
            name: "Name",
            email: "Email",
            password: "Password",
            newPassword: "New Password",
            confirmPassword: "Confirm Password",
            redeem: "Redeem",
            reveal: "Reveal",
            redeemed: "Redeemed",
            claim: "Claim",
            available: "Available",
            contract: "Contract",
            seeDescription: "See Description",
            redeemBy: "Redeem by",
            continue: "Continue",
            resendInvoice: "Resend Invoice",
            sendInvoice: "Send Invoice",
            invoiceSent: "Invoice Sent",
            completed: "Completed",
            cancelled: "Cancelled",
            createdAt: "Created at",
            quantity: "Quantity",
            paid: "Paid",
            seeDetails: "See Details",
            address: "Address",
            vat: "Vat",
            seeMembershipTiers: "See Membership Tiers",
            privacyPolicy: "Privacy Policy",
            termsAndConditions: "Terms & Conditions",
            connect: "connect",
            seeLess: "See Less",
            seeMore: "See More",
            free: "Free",
            join: "Join",
            subscribe: "Subscribe",
            buy: "Buy",
            price: "Price",
            upgradingSubscription: "Upgrading Subscription",
            profile: "Profile",
            editProfile: "Edit Profile",
            logout: "Logout",
            limitedSupply: "Limited Supply",
            expirationDate: "Expiration Date",
            description: "Description",
            claimYour: "Claim Your",
            pricing: "Pricing",
            no: "No",
            yes: "Yes",
            showOnChain: "Show On-chain",
            claimOnChain: "Claim On-chain",
            share: "Share",
            downloadImage: "Download Image",
            downloadPDF: "Download PDF",
            manageSubscription: "Manage Subscription",
            error: "Error",
            seeCollection: "See Collection",
            buyNow: "Buy now",
            billingDetails: "Billing Details",
            recipientDetails: "Recipient Details",
            completePayment: "Complete Payment",
            paymentConfirmation: "Payment Confirmation",
            giftSent: "Gift Sent",
            next: "Next", //context checkout stepper
            of_sc: "of", //context checkout stepper
            paymentMethods: "Payment Methods",
            phoneNumber: "Phone Number",
            proceed: "Proceed",
            city: "City",
            postCode: "Post Code",
            billingAddress: "Billing address",
            contactDetails: "Contact Details",
            personalDetails: "Personal Details",
            newsletterPreferences: "Email Preferences",
            connectedAccounts: "Connected Accounts",
            clickHere: "Click Here",
            from: "From",
            to: "To",
            upgradeMembership: "Upgrade Membership",
            members_sc: "members",
            none_sc: "none",
            seeMemberships: "See Memberships",
            once_sc: "once",
            times_sc: "times", //vezes
            store: "Store",
            after: "after",
            renewsOn: "Renews On",
            expiresOn: "Expires On",
            downgrading_sc: "downgrading",
            memberships: "Memberships",
            monthly: "Monthly",
            quarterly: "Quarterly",
            yearly: "Yearly",
            orderSummary: "Order Summary",
            payment_sc: "payment",
            joinNow: "Join Now",
            submit: "Submit",
            view: "View",
            revealSoon: "Reveal Soon",
            claiming: "Claiming",
            settings: "Settings",
            firstName: "First Name",
            lastName: "Last Name",
            portuguese: "Portuguese",
            english: "English",
            update: "Update",
            walletAddress: "Wallet Address",
            deliveryDetails: "Delivery Details",
            preferences: "Preferences",
            membershipPasses: "Membership Passes",
            digitalCollectibles: "Digital Collectibles",
            congratulations: "Congratulations",
            disconnectWallet: "Disconnect Wallet",
            showAll: "Show All",
            filterBy: "Filter By",
            organization: "Organization",
            you: "You",
            other_sc: "other",
            like: "Like",
            comment: "Comment",
            liked: "Liked",
            billingHistory: "Billing History",
            help: "Help",
            reactivate: "Reactivate",
            downgrading: "Downgrading",
            toFree: "to Free",
            unclaimed: "unclaimed",
            viewNFT: "View NFT",
            about: "About",
            links: "Links",
            newsletter: "Newsletter",
            more: "More",
            month_sc: "month",
            privateLink: "Private Link",
            receive: "Receive",
            points_sc: "points",
            loadMore: "Load More",
            seeLatest: "See Latest",
            country: "Country",
            soldOut: "Sold Out",
            viewMore: "View More",
            viewAll: "View All",
            claimWithNFT: "Claim with NFT",
            discount: "Discount",
            cancel: "Cancel",
            goTo: "Go to Club",
            welcome: "Welcome",
            buyTickets: "Buy Tickets",
            save: "Save",
            unsubscribe: "Unsubscribe",
            events: "Events",
            tickets: "Tickets",
            upcoming: "Upcoming",
            past: "Past",
            here: "here",
            freeEntrance: "Free Entrance",
            ticketDetails: "Ticket Details",
            location: "Location",
            reason: "Reason",
            close: "Close",
            applicationFee: "Service Fees",
            transactionConfirmed: "Payment Completed",
            now: "Now",
            confirm: "Confirm",
        },
        errors:{
            invalidTicketEmail: "Please provide a valid email for ticket",
            nameMissing: "Please tell us your name",
            emailMissing: "Please provide a valid email address",
            passwordMissing: "Please provide a valid password",
            passwordsDontMatch: "Passwords do not match",
            dataAgreementMissing: "Please read and agree to our terms and conditions",
            countryMissing: "Please tell us your country",
            postalCodeMissing: "Please tell us your post code",
            notEligiblePerk: "You don't meet the criteria to redeem this perk. If you believe this to be a mistake please contact the organization who issued it",
            default: "Something went wrong, please try again. If the problem persists please contact the administrator",
            userExists: "An account already exists with the given email. Please use a different email or login with the existing account.",
            emailValidationFailed: "There was an error validating your email address. Please try again.",
            giftEmailMissing: "Please tell us the email address of the person you would like to send this {{rewardCategoryLabel}} to.",
            giftNameMissing: "Please tell us the name of the penson you would like to send this {{rewardCategoryLabel}} to.",
            giftSenderEmailMissing: "Please tell us your email address.",
            redeemedMax: "You have redeemed this perk the maximum amount of times.",
            manualSubscription: "Your active subscription can only be altered manually by one of your club's administrators. Please reach out if you require some assistance.",
            nftSubscription: "Your active subscription can only be altered manually by one of your club's administrators. Please reach out if you require some assistance.",
            passAlreadyIssued: "You already have this pass.",
            unableToValidateOnchain: "The digital collectible associated with this offer was claimed on-chain as an NFT. Unfortunately, on-chain NFT verification is not available yet but our team is working to launch it soon. We will get in touch with you as soon as it's ready, so you can redeem your offer.",
            commentEmpty: "Comments cannot be empty",
            alreadyIssuedMax: "You have already been issued this digital collectible the maximum number of times.",
            notEligible: "Your account is not eligible to claim this digital collectible. Please contact the organization if you think this is not correct.",
            privateClaim: "*Your e-mail, or Membrz Club account, must be eligible to claim this digital collectible. Please contact the organization if you're not being able to claim it.",
            unavailable: "This digital collectible is no longer available.",
            notFound: "Not found",
            unableToValidateCredentials: "Unable to validate credentials",
            unableToValidateSignature: "Unable to validate signature",
            noPaymentProviders: "Unable to load a valid payment provider. Please try again later.",
            stripeNotConnected: "Unable to connect to Stripe. Please try again later.",
            pleaseRefresh: "Please refresh the page and try again.",
            fieldMissing: "Field missing: {{field}}",
        },
        tcLabel:{
            agreement: "I agree to Membrz Club's",
        },
        //containers
        account:{
            forgotPassword:{
                errorEmailMissing: "Please provide a valid email address",
                requestInstructions: "Send reset instructions",
                resetInstructions: "An email with instructions was sent to the email address you provided. Please check your email.",
            },
            invite:{
                header: "{{organizationName}} invited you to",
                content: "Sign up to collect NFT badges and certificates to this {{category}}",
            },
            login:{
                pageTitle: "Login & manage your Members' Club!", //this is also shown to the end user. is "magage" appropriate here? Suggestion: Access/Enter/Visit Membrz Club!
                button: "Login",
            },
            resetPassword:{
                newUserPageTitle: "Welcome to Membrz Club!",
                newUserPageDescription: "Create a password to see and share your new NFT and manage your collection!",
            },
            common:{
                forgotPasswordAndResetPasswordPageTitle: "Forgot your Password?",
                forgotPasswordAndResetPasswordPageDescription: "It happens to the best of us. We'll help you reset it and get back on track.",
            }
        },
        //public:{
            buyActions:{
                frequencySwitcher:{
                    monthly: "per month",
                    yearly: "per year",
                    quarterly: "per quarter",
                    lifetime: "one time payment",
                },
                giftCheckboxlabel: "This is a gift.",
            },
            //not checkout modal being used through profile
            checkoutModal:{
                utrustAwaitingConfirmationParagraphFirst: "We are waiting for payment confirmation by xMoney.",
                utrustAwaitingConfirmationParagraphSecond: "You will be redirected to your profile soon after it's complete.",
                utrustAwaitingConfirmationParagraphThird: "You can also close this window and return later to claim the NFT after payment is confirmed.",
                mbwayAwaitingConfirmationParagraphFirst: "We are waiting for confirmation that your payment was successful.",
                mbwayAwaitingConfirmationParagraphSecond: "Please open your MB WAY app and confirm the payment there.",
                lookingForRewardInstance: "You will be redirected to your profile shortly.",
                processingOrder: "We are processing your order.",
                thankYouForPayment: "Thank you for your payment",
                rewardInstanceNotFound: "Something went wrong, please click HERE to be redirected to your profile and contact the collection administrator.",
                finalAmountHigherThanZero: "A single user can only have {{claimLimit}} instances of this NFT. As such your order amount was reduced from {{amount}} to {{finalAmount}}.",
                priceUpdated: "Your order price has also been updated to {{finalPrice}} {{currencySymbol}}.",
                orderStarted: "Your order has been started.",
                utrustIntro: "You will be or have been redirected to the xMoney payment widget, compatible with any major crypto wallet. Please follow the instructions and confirm your purchase below when all the steps are completed.",
                utrustInstructions: "If you have not been redirected to the xMoney payment widget, ensure your browser is not blocking pop-ups from Membrz Club or ",
                changedMyMind: "Changed my Mind",
                paymentComplete: "Complete Payment",
                proceedToUtrust: "Take me to xMoney",
                pay: "Pay {{finalPrice}} {{currencySymbol}}",
                paymentProviderSelectorDescription: "Please choose your preferred payment option:",
                googlePayFailed: "Google Pay is not available. Please choose a different payment method.",
                giftBeingSent: "We will shortly send you an email with your order details and confirmation that your gift was sent.",
                inStock: "In stock. Max supply: ",
                invoiceForm:{
                    description: "Please add your details below.",
                },
                giftModal:{
                    title: "Gift this {{rewardCategoryLabel}} to someone.",
                    description: "Please add a message and details below to whom you want to give this {{rewardCategoryLabel}}. The {{rewardCategoryLabel}} will be sent by email.",
                },
                giftForm:{
                    message: "Special message (400 char max)",
                    senderName: "Your name",
                    senderEmail: "Your email",
                    name: "Recipient's name",
                    email: "Recipient's email",
                },
           },
           profile:{
                unsubscribe: "Your logged in as: {{user_email}}. Please confirm your desire to stop receiving newsletters from {{organization_title}} by clicking the button below.",
                unsubscribeSuccess: "Preferences updated",
                events:{
                  pageDescription: "See all the events",
                },
                perks:{
                    noHiddenFees: "No hidden fees",
                    applicationFeesApply: "Service fees apply",
                    redeemedSuccessfully: "Product successfully purchased!",
                    insuficientPointsMessage: "You need {{points}} points to access this product. You can earn a different amount of points monthly depending on your membership.",
                    exclusivePerkMessage: "Exclusive to {{tierTitles}} subscribers.",
                    discountPerkMessage: "Exclusive {{discount_percentage}}% discount.",
                    privatePerkMessage: "Open to all members.",
                    redeemedCountMessage: "You have redeemed this product ",
                    redeemModal:{
                        rsvpStatementOfAction: "You are about to confirm your RSVP to {{eventTitle}}.",
                        statementOfAction: "You are about to redeem {{title}}.",
                        confirmationMessage: "Would you like to continue?",
                        reasonLabel: "Please tell us more details on why you're redeeming this perk.",
                        criteria:{
                            points: "Redeeming this product will cost you {{points}} points. You currently have {{userPointsLabel}}.",
                            private: "This product is exclusive to members.",
                            exclusive: "This product is exclusive to members of the following tier{{suffix}}: {{tierTitles}}.",
                            date: "This product expires {{formatedEndDate}}.",
                            supply: "Only {{available_amount}} left.",
                        },
                    },
                    pageDescription: "Get access to exclusive perks just for members",
                },
                memberships:{
                    memberPageDescription: "Upgrade your plan by selecting another membership option",
                    pageDescription: "Select the membership option most suitable to you",
					paymentFrequency: {
                        monthly: "paid monthly",
                        yearly: "paid annually",
                        quarterly: "paid quarterly",
                        lifetime: "one time payment",
                    },
                    claimModal:{
                        billingDetailsCTA: "Add billing details",
                        ticketDetailsCTA: " Add ticket information",
                        singleTicketDescription: "You will receive your ticket in digital format at the email associated with your account.\nOn the event date, use the QR Code to enter.\n\nIf you do not receive the ticket within the next few minutes, please check your Spam folder.\n\nAfter confirming that the ticket is not in your Spam folder, please contact us at hello@membrz.club and we will look into the situation as soon as possible",
                        singleTicketAtTheDoorDescription:"You will also receive your ticket in digital format at the email associated with your account",
                        atTheDoorModalTitle: "Your Ticket{{suffix}}!",
                        atTheDoorModalDescription: "Thank you for your purchase. Show the QR Code{{suffix}} above to enter the venue.",
                        multipleTicketDescription: "You will receive all tickets in digital format at the email associated with your account.\nAditionally, if you would like the tickets to be sent to other addresses, click the button below.\n\nOn the event date, use the QR Code to enter.\n\nIf any of the ticket is not received within the next few minutes, please check the Spam folder.\nAfter confirming that the ticket has not been marked as Spam, please contact us at hello@membrz.club and we will check the situation as soon as possible.\n\nTo add your billing details without associating other email addresses to this purchase, please click ",
                        multipleAtTheDoorTicketDescription: "You will also receive all tickets in digital format at the email associated with your account.\nAditionally, if you would like the tickets to be sent to other addresses, click the button below.\n\nTo add your billing details without associating other email addresses to this purchase, please click ", 
                        upgradeSuccessfulMessage: "Your subscription has been upgraded",
                        upgradeConfirmationMessage: "After confirming that you would like to proceed with the upgrade of your subscription we will attempt to to charge you for it on your saved card details. Would you like to continue?",
                        thankYouForPayment: "Thank you for your payment!",
                        ticketsSent: "Thank you. Tickets sent to the requested email addresses.",
                        welcomeToTheClub: "Welcome to the club!",
                        back: "Back",
                        totalDue: "Total due today",
                        renewalTimingsFrequency:{
							monthly: "monthly",
							yearly: "yearly",
							quarterly: "quarterly",
						},
                        renewalTimings: "You'll pay {{itemPriceWithCurrency}} today, and then {{translatedFrequency}} on the {{day}}. Your next charge will be on {{date}}.",
                        renewalTimingsSecond:"This subscription automatically renews {{translatedFrequency}}, and you'll be notified in advance if the amount to pay increases. You will be able to cancel your subscription at any time.",
                        invoiceFormDescription: "Please add your details below.",
                        invoiceCreated: "Your invoice has been created and a copy will shortly be sent to your email.",
                        ticketInvoiceCreated: "Your invoice has been created. You can manage all your invoices ",
                        summary: {
                            monthly: "Monthly payment",
                            yearly: "Yearly payment",
                            quarterly: "Quarterly payment",
                            lifetime: "One time payment",
                        },
                        buyNowWithMbWay: "Buy now with MBWay",
						processingEupago: "Please confirm this transaction on your MBWay app and click the button below.",
                    },
                },
                sidebar:{
                    joinTheClub: "Join the club",
                },
                about:{
                    privateElement: "This link is private for community member's only. Please become a member first.",
                    exclusiveElement: "This link is private for subscribers of the following tier{{suffix}}: {{tierTitles}}.",
                    brandingButton: "Create a Free Membrz.Club",
                },
                connectModal:{
                    loggedInContent: "Connect your wallet and claim your membership pass.",
                    loggedOutContent: "Connect your wallet to create a MembrzClub account and claim your membership pass.",
                    step2Contnet: "Your MembrzClub account has been created. Please provide a valid email address to conclude your subscription process.",
                },
                freeSubscriptionModal:{
                    promptToSubscribe: "Do you like what you are reading? Don't miss any update. Joining the club is as easy as typing your email below!",
                    emailPrompt: "Type your email...",
                    passwordPrompt: "Type your password...",
                    downgradingToFree: "To downgrade to the free tier you only need to cancel your current subscription. You will be able to enjoy all its benefits until its expiration date. Once the cancelled subscription expires, we will move you to the selected tier. Until then, you will be able to reactivate your subscription.",
                    agreement: "By registering you agree to Membrz Club's"
                }

            },
            publicReward:{
                //duplicated label
                shareMessage: "Share it with the world",
                addThisRewardMessage: "Add this unique {{rewardCategoryLabel}} to your collection. Takes less than 1 minute.",
            },
        //},
        student:{
            tickets:{
				pageTitle: "Tickets",
				pageDescription: "Here you can see and manage all your tickets",
				noneFoundMessage: "No tickets found",
			},
            merch:{
                detailsUpdated: "Merch drop details updated",
                incomplete_address: "Please tell us your full address",
                shirtSize: "T-shirt Size *",
            },
            profile:{
                shareModalMessage: "You are the new owner of",
            },
            onChainModal:{
                title: "Claim Membership Pass as NFT?",
                contentFirst: "If you have a Web3 Wallet (like Metamask) you can withdraw your membership pass, as an NFT on-chain.",
                contentSecond: "This will allow you to showcase your membership in your wallet and also have access to Token-Gated applications on Membrz.Club",
                contentThird: "If you don't have one now, don't worry! You can always come back and associate a Web3 Wallet later.",
                laterButtonLabel: "No, I'll do it later!",
            },
            orders:{
                pageTitle: "Orders",
                pageDescription: "Here you can see and manage all your orders",
                noneFoundMessage: "No orders found",
            },
            offers:{
                pageTitle: "Perks",
                pageDescription: "Here you can see all the perks available to you",
                noOffers: "There are no perks available at this time",
                noOffersRedeemed: "You have not redeemed any perks yet",
                redeemedSuccessfullyMessage: "You redeemed this perk successfully.",
                redeemModal:{
                    firstLabel:'You are about to redeem one perk for {{title}} by {{course}}.',
                    firstLabelExternal: 'You are about to redeem one perk for {{title}}.',
                    secondLabel:'By redeeming it you will receive an e-mail with instructions on how to activate it in your contact address associated with Membrz Club.',
                    thirdLabel:'Do you confirm you want to redeem the perk ?',
                    redeemButtomCancel: 'No. I want to think about it a bit more.',

                },
            },
            preferredLanguage: "Please choose your preferred language",
            connectAccountModal:{
                title:{
                    telegram: 'Set up Telegram username',
                    whatsapp: 'Connect your WhatsApp account',
                },
                description:{
                    telegram: 'For security reasons, we only allow to set up the Telegram username. If you do not have one yet, open your Telegram app, go to Settings and add it.',
                    whatsapp: 'Join Exclusive WhatsApp groups and communities by adding your phone number below and linking your WhatsApp account.',
                },
                inputLabel:{
                    telegram: 'Telegram username',
                    whatsapp: 'Phone number',
                },
                showJoin: 'To join this Telegram Group, you need to connect your Telegram account to your Membrz Club account.'
            }
        },
        ui:{
            postCard:{
                commentPlaceholder: "Add a new comment",
                exclusive: "This content is only available to premium subscribers of the following tiers: {{tierTitles}}",
                privateOrFree: "This content is only available to club members",
                becomeAMember: "Become a member",
                becomeAFreeMember: "Join for Free",
				becomeAPremiumMember: "Become Premium",
                becomeAMemberDescriptionFirst: "You need to be a member to interact with this community.",
				becomeAMemberDescriptionSecond: "Would you like to become one now?",
            }
        },
        useSubscriptionController:{
            cancelSubscription: "Cancel Subscription",
            reactivateSubscription: "Reactivate Subscription",
            cancelScheduledChange: "Cancel Scheduled Change",
            updatePaymentDetails: "Update Payment Details",
            confirmModalMessage:{
                mustReactivate: "You have asked us to cancel your subscription. If you want to change your tier, please reactivate your subscription first. Would you like to do it now?",
                mustCancelDowngrade: "Your subscription is due to be downgraded to {{futureRewardTitle}}. If you would like to change the the subscription you are downgrading to, please cancel the currently scheduled change. Would you like to do this now?",
                downgradetoFreeInstructions: "To downgrade to the free tier you only need to cancel your current subscription. You will be able to enjoy all its benefits until its expiration date. Once the cancelled subscription expires, we will move you to the selected tier. Until then, you will be able to reactivate your subscription. Would you like to continue?",
                downgradeInstructions: "By downgrading to this tier you will cancel your current subscription. You will be able to enjoy all its benefits until its expiration date. Once the cancelled subscription expires, we will move you to the selected tier. Until then, you will be able to reactivate your subscription. Would you like to continue?",
            },
            expiredSubscription: "New charges will be attempted automatically.",
        },
        //___________________________________________________________________________________________________________________________________________________________________________________
        //components
        authForms:{
            loginForm:{
                forgotPasswordQuestion: "Forgot your password?",
                forgotPasswordLink: "Reset it here.",
                newToMembrzQuestion: "New to Membrz Club? ",
                signUpCall: "Sign up now!",
            },
            resetForm:{
                successMessage: "You have sucessfully reset your password. 🎉",
                claimingMessage: "Claiming your badge now.",
                redirectMessage: "Redirecting to your profile.",
                requestInstructions: "Set Password and Claim"
            },
            signUpForm:{
				instruction: "Please provide your e-mail address to proceed.",
				alreadyRegisteredQuestion: "If you already have a Membrz Club account please ",
                signInCall: "sign in",
			},
            authModal:{
                welcome: "Welcome to Membrz Club!",
                getStarted: "Get Started!",
                welcomeBack: "Welcome back!",
            },
            common:{
                agreementDeclaration: "I agree to Membrz Club's Terms & Conditions and Privacy Policy",
            }
        },
        // not ui component. currently not being used? duplicated for easier deletion with component with not required
        offerCard:{
            externalEligibilityLabel: "To reedeem this perk you need to own one {{rewardCategoryLabel}} with the following attributes",
            internalElegilibityLabelReward: "To reedeem this perk you need to own the following {{rewardCategoryLabel}}",
            internalElegilibityLabelCourse: "To reedeem this perk you need to own one {{rewardCategoryLabel}} from the following organization:",
            offerRedeemedWith: "You redeemed this perk with:",
            validatingRequestMessage: "Please give us a moment to validate your request",
            awaitingConfirmationMessage: "You will be contacted soon",
        },
        orderCard:{
            referenceNotFound: "Reference no. not found",
        },
        // not ui component. currently not being used? duplicated for easier deletion with component if not required
        postCard:{
            lockedPost: "This content is only available to subscribers of the following tiers:",
        },
        // not claim model in use through profile. currently not being used? duplicated for easier deletion with component if not required
        claimModal:{
            upgradeSuccessfulMessage: "Your subscription has been upgraded",
            upgradeConfirmationMessage: "After confirming that you would like to proceed with the upgrade of your subscription we will attempt to to charge you for it on your saved card details. Would you like to continue?"
        },
        layouts:{
            public:{
                pagePrimaryDescription: "Membrz Club helps brands, organizations & creators to connect with their audience using Digital Collectibles & NFTs.",
                pageSecondaryDescription: "Want to learn more?",
                here: "Check it here"
            },
        },
        rewardPreview:{
            metadata:{
                activityTitle: "Collection Title",
                activityType: "Collection Type",
                claimedAt: "Claimed At",
                issuedAt: "Issued At",
                issuerName: "Issuer Name",
                tokenType: "Token Type",
            },
            onboarding:{
                onboardingSubtitleFirst: "Membrz Club collectibles",
                onboardingTextFirst: "Membrz Club collectibles are exclusive digital gifts issued as NFTs, celebrating unique life moments, experiences and achievements.",
                onboardingSubtitleSecond: "Never heard about NFTs?",
                onboardingTextSecond: "NFT (Non-Fungible-Tokens) are unique and verifiable digital tokens on the blockchain. They can represent different digital assets, are fully owned by you, and cannot be forged. The process to claim an NFT with Membrz Club is simple and no wallet or web3 experience is required.",
            },
        },
        feedBackUser:{
            currentlyLoggedInAs: "You're currently logged in as", //suggestion: Currently logged in as
        },
        shareRewardModal:{
            defaultButton: "View and share your {{rewardCategoryLabel}} here!",
            certificateButton: "View and share!",
            //duplicated label
            shareMessage: "Share it with the world",
        },
        utils:{
            copyToClipboard:{
                success: "Copied to clipboard!",
                failure: "Copy failed! {{error}}",
            }
        },
        rewardType:{
            behaviour: "NFT",
            achievement: "Certificate",
            collectible: "Collectible",
            tier: "Tier"
        },
		customFields:{
			"asa-perafita":{
				matricula: "Licence Plate"
			},
			unicornfactorylisboa:{
				startup: "Startup"
			}
		}
    },
};

export { labels };
