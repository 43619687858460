import React from 'react';
import PropTypes from 'prop-types';

import CardGrid from './CardGrid';

const EventsList = ({events}) => {

  return (
    <div className='events-content'>

        <CardGrid events={events} />
    </div>
  );
};

EventsList.propTypes = {
  events: PropTypes.array,
};

export default EventsList;
