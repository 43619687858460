export const offerType = {
    internal: 'InternalOffer',
    external: 'ExternalOffer',
};

export const offerCategory = {
    merch: { value: 'merch', label: 'Merchandise' },
    ticket: { value: 'ticket', label: 'Ticket' }, 
    perk: { value: 'perk', label: 'Perk' },
};

export const emptyOfferDefinition = {
    title: '',
    description: '',
    extended_description: '',
    tag_ids: [],
    file: null,
    filename: '',
    price: '',
    currency: "USD",
    coupons: [],
    points: '',
    supply_limit: '',
    redeem_limit: '',
    end_date: '',
    show_end_date: false,
    show_supply: false,
    status: "active",
    notification_list: "",
    slug: '', 
};

export const emptyCoupon = {
    tier_title: '',
    tier_id: '',
    percentage_off: '',
};

export const emtpyInternalOffer = {
    reward_ids: ['public'],    
};

export const emtpyTextOffer = {
    content_text: '',
    content_text_link: ''
};

export const defaultOffer = {
    ...emptyOfferDefinition,
    ...emtpyInternalOffer,
    ...emtpyTextOffer,
};

export const visibilityOptions = [
    { value: 'always', label: 'Always visible, even after selling out'},
    { value: 'available', label: 'Only visible when in stock / within end date'},
    { value: 'secret', label: 'Hidden from listings (Secret)'}
];