import React from "react";
import PropTypes from "prop-types";
import FormSection from "ui/FormSection";
import Description from "ui/text/Description";
import { IconButton } from "@mui/material";
import TicketEditor from "./TicketEditor";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";

const SessionTicketsEditor = ({ event, onEventChange, setUnsavedChanges }) => {
  const { session_tickets } = event;

  const handleAddTicket = () => {
    setUnsavedChanges(true);
    let newEvent = { ...event, session_tickets: [...session_tickets, {}] };
    onEventChange(newEvent);
  };

  return (
    <FormSection title={"Tickets"}>
      <TicketEditor
        event={event}
        onEventChange={onEventChange}
        setUnsavedChanges={setUnsavedChanges}
      />

      <div className={"event-form_ticket center"}>
        <Description>Add New Ticket</Description>
        <IconButton onClick={handleAddTicket}>
          <AddCircleOutline color="warning" />
        </IconButton>
      </div>
    </FormSection>
  );
};

SessionTicketsEditor.propTypes = {
  event: PropTypes.object,
  onEventChange: PropTypes.func,
  setUnsavedChanges: PropTypes.func,
};

export default SessionTicketsEditor;
