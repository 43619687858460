import React from 'react'
import PropTypes from 'prop-types'
import { Divider, IconButton, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import Label from 'ui/text/Label';

const TicketEditor = ({event, onEventChange, setUnsavedChanges}) => {

  const { session_tickets } = event;

  const handleChange = (e, index) => {
    setUnsavedChanges(true)
    const { name, value } = e.target
    const newTickets = [...session_tickets]
    newTickets[index][name] = value
    let newEvent = { ...event, session_tickets: newTickets };
    onEventChange(newEvent)
  };

  const handleDelete = (index) => {
    setUnsavedChanges(true)
    const newTickets = [...session_tickets]
    newTickets.splice(index, 1)
    let newEvent = { ...event, session_tickets: newTickets }
    onEventChange(newEvent)
  };

  const showDivider = (index) => (session_tickets.length > 1 && index !== session_tickets.length - 1)

  return (
    <div className="event-form-ticket_editor">
      {session_tickets.map((ticket, index) => (
        <div key={index} className='event-form-ticket'>
          <TextField
            name="title"
            label="Title *"
            value={ticket.title}
            onChange={(e) => handleChange(e, index)}
            fullWidth
            size="small"
            color="warning"
            InputProps={{
              endAdornment: (
                <IconButton highlighted onClick={() => handleDelete(index)}>
                  <DeleteIcon color='warning'/>
                </ IconButton>
              )
            }}
          />

          <div key={index} className='row'>
            <TextField
              name="supply_limit"
              label="Supply Limit"
              value={ticket.supply_limit}
              onChange={(e) => handleChange(e, index)}
              fullWidth
              size="small"
              color="warning"
            />
            <TextField
              name="price"
              label="Price"
              value={ticket.price}
              onChange={(e) => handleChange(e, index)}
              fullWidth
              size="small"
              color="warning"
            />
            <Label secondary padded>Leave empty for unlimited or free</Label>
          </div>

          {showDivider(index) && 
            <Divider light sx={{marginBottom: 2}} />
          }
        </div>
      ))}
    </div>
  )
}

TicketEditor.propTypes = {
    event: PropTypes.object,
    onEventChange: PropTypes.func,
    setUnsavedChanges: PropTypes.func
}

export default TicketEditor