import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Memberships from 'containers/teacher/Memberships';
import Dashboard from 'containers/teacher/Dashboard';
import OrganizationSettings from 'containers/teacher/Settings';

import ImportStudent from 'containers/teacher/Student/Wizard/import';
import NewStudent from 'containers/teacher/Student/Wizard/new';
import Students from 'containers/teacher/Community';

import Analytics from 'containers/teacher/Analytics';

import NewReward from 'containers/teacher/Reward/Wizard/new';
import UpdateReward from 'containers/teacher/Reward/Wizard/update';
import SendReward from 'containers/teacher/Reward/Send';

import NewCourse from 'containers/teacher/Course/Wizard/new';
import UpdateCourse from 'containers/teacher/Course/Wizard/update';
import OffersTeacher from 'containers/teacher/Offers';
import UpdateOffer from 'containers/teacher/Offers/Wizard/update';
import NewOffer from 'containers/teacher/Offers/Wizard/new';
import Elements from 'containers/teacher/Elements';

import StudentProfile from 'containers/student/Profile';

import Post from 'containers/teacher/Post';
import NewPost from 'containers/teacher/Post/Wizzard/new';
import UpdatePost from 'containers/teacher/Post/Wizzard/update';

import AdminUsers from 'containers/admin/Users';
import AdminCourses from 'containers/admin/Courses';
import AdminCoursesView from 'containers/admin/Courses/View';
import AdminOrganizations from 'containers/admin/Organizations';
import AdminPosts from 'containers/admin/Posts';
import AdminOrders from 'containers/admin/Orders';
import AdminEvents from 'containers/admin/Events';

import ClaimReward from 'containers/public/ClaimReward';
import PublicReward from 'containers/public/publicReward';
import Profile from 'containers/public/Profile';
import Unsubscribe from 'containers/public/Unsubscribe';

import Invite from 'containers/account/invite';
import ForgotPassword from 'containers/account/forgotPassword';
import ResetPassword from 'containers/account/resetPassword';
import Login from 'containers/account/login';

import Offers from 'containers/student/Profile/Offers';
import UserOrders from 'containers/student/Profile/Orders';
import UserSettings from 'containers/student/Settings';
import UserTickets from 'containers/student/Profile/Tickets';
import OrganizationWizzard from 'containers/teacher/Organization/Wizzard';
import Subscriptions from 'containers/teacher/Community';
import Onboarding from 'containers/teacher/Onboarding';
import Redirect from 'containers/teacher/Redirect';
import Event from 'containers/teacher/Event';
import NewEvent from 'containers/teacher/Event/Wizzard/new';
import UpdateEvent from 'containers/teacher/Event/Wizzard/update';
import Tickets from 'containers/teacher/Tickets';
import ConnectRedirect from 'containers/teacher/ConnectRedirect';

import CheckinTickets from 'containers/checkin/Tickets';

import AdminTickets from 'containers/admin/Tickets';
import AdminProducts from 'containers/admin/Products';

const Routes = () => {
  return(
    <BrowserRouter>
      <Switch>

        { /* Admin routes*/ }
        <Route path="/admin/users" component={ AdminUsers } />
        <Route path="/admin/memberships/:courseId" component={ AdminCoursesView } />
        <Route path="/admin/memberships" component={ AdminCourses } />
        <Route path="/admin/organizations" component={ AdminOrganizations } />
        <Route path="/admin/orders" component={ AdminOrders } />
        <Route path="/admin/posts" component={ AdminPosts } />
        <Route path="/admin/events" component={ AdminEvents } />
        <Route path="/admin/tickets" component={ AdminTickets } />
        <Route path="/admin/products" component={ AdminProducts } />

        { /* Organization routes*/ }
        <Route path="/organizations/:organizationId/memberships/:courseId/tiers/new" >
          <NewReward membership={ true } />
        </Route>
        <Route path="/organizations/:organizationId/memberships" component={ Memberships } />

        <Route path="/organizations/:organizationId/collections/new" component={ NewCourse } />

        <Route path="/organizations/:organizationId/collections/:courseId/collectible/:rewardId/edit" component={ UpdateReward } />
        <Route path="/organizations/:organizationId/collections/:courseId/collectible/:rewardId" component={ SendReward } />
        <Route path="/organizations/:organizationId/collections/:courseId/collectibles/new" component={ NewReward } />
        <Route path="/organizations/:organizationId/collections/:courseId/collectibles" component={ Dashboard } />

        <Route path="/organizations/:organizationId/collections/:courseId/participants/import" component={ ImportStudent } />
        <Route path="/organizations/:organizationId/collections/:courseId/participants/new" component={ NewStudent } />

        <Route path="/organizations/:organizationId/collections/:courseId/edit" component={ UpdateCourse } />
        <Route path="/organizations/:organizationId/collections/:courseId/" component={ Dashboard } />

        <Route path="/organizations/:organizationId/community" component={ Subscriptions } />
        <Route path="/organizations/:organizationId/participants" component={ Students } />
        <Route path="/organizations/:organizationId/analytics" component={ Analytics } />

        <Route path="/organizations/:organizationId/perks/:offerId/edit" component={ UpdateOffer } />
        <Route path="/organizations/:organizationId/perks/new" component={ NewOffer } />
        <Route path="/organizations/:organizationId/perks/" component={ OffersTeacher } />

        <Route path="/organizations/:organizationId/events/:eventId/tickets" component={ Tickets } />
        <Route path="/organizations/:organizationId/events/:eventId/edit" component={ UpdateEvent } />
        <Route path="/organizations/:organizationId/events/new" component={ NewEvent } />
        <Route path="/organizations/:organizationId/events/" component={ Event } />

        <Route path="/organizations/:organizationId/elements/" component={ Elements } />

        <Route path="/organizations/:organizationId/content/:postId/edit" component={ UpdatePost } />
        <Route path="/organizations/:organizationId/content/new" component={ NewPost } />
        <Route path="/organizations/:organizationId/content/" component={ Post } />

        <Route path="/organizations/:organizationId/settings" component={ OrganizationSettings } />

        <Route path="/organizations/:organizationId/start" component={ Onboarding } />
        <Route path="/organizations/:organizationId" component={ Redirect } />

        <Route path="/organizations/new" component={ OrganizationWizzard } />

        <Route path="/connect/settings" component={ ConnectRedirect } />
        <Route path="/connect/payouts" component={ ConnectRedirect } />
        <Route path="/connect/orders" component={ ConnectRedirect } />
        <Route path="/connect" component={ ConnectRedirect } />

        { /* Participant routes*/ }
        <Route path="/profile" component={ StudentProfile } />
        <Route path="/settings" component={ UserSettings } />
        <Route path="/offers/:organizationId" component={ Offers } />
        <Route path="/offers" component={ Offers } />
        <Route path="/orders" component={ UserOrders } />
        <Route path="/tickets" component={ UserTickets } />

        { /* Public routes*/ }
        <Route path="/checkin/:eventUuid" component={ CheckinTickets } />
        <Route path="/unsubscribe/:uuid/:organization_id" component={ Unsubscribe } />
        <Route path="/claim/:organizationId/preview" component={ ClaimReward } />
        <Route path="/claim/:tokenId/:resetToken" component={ ClaimReward } />
        <Route path="/claim/:tokenId/" component={ ClaimReward } />
        <Route path="/tokens/:tokenId/" component={ PublicReward } />
        <Route path="/invites/:inviteId/" component={ Invite } />
        <Route path="/password_resets/:resetToken" component={ ResetPassword } />
        <Route path="/password_resets" component={ ForgotPassword } />

        <Route path="/login" component={ Login } />
        <Route path="/:organizationSlug" component={ Profile } />
        <Route path="/" component={ Login } />

      </Switch>

    </BrowserRouter>
  )
}

export default Routes;
