import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';

import Button from 'ui/Button';
import Description from 'ui/text/Description';

import { t } from "i18n/index";

const PaymentProcessed = ({ setStep, handleClaimReset, stripe_purchase, isTicket, amount, atTheDoor }) => {
  
  const {organizationSlug} = useParams();
  const history = useHistory()

  const handleRedirect = () => {
    handleClaimReset();
    history.push(`/${organizationSlug}`);
  };

  const handleRedirectToBillingDetails = () => {
    handleBillingDetails()
  };

  const handleTicketDetails = () => setStep(3);

  const handleBillingDetails = () => setStep(5);
  
  const description = stripe_purchase ? t('profile:memberships:claimModal:thankYouForPayment') : t('profile:memberships:claimModal:welcomeToTheClub');

  const singleTicketDescription = atTheDoor ? t('profile:memberships:claimModal:singleTicketAtTheDoorDescription') : t('profile:memberships:claimModal:singleTicketDescription');
  const multipleTicketDescription = atTheDoor ? t('profile:memberships:claimModal:multipleAtTheDoorTicketDescription') : t('profile:memberships:claimModal:multipleTicketDescription');
  const ticketDescription = amount === 1 ? singleTicketDescription : multipleTicketDescription;

  const backButtonLabel = stripe_purchase ?  t('literal:close') : t('profile:memberships:claimModal:back');

  const nextButtonAction = isTicket && amount > 1 ? handleTicketDetails : handleBillingDetails;
  const nextButtonLabel = isTicket && amount > 1 ? t('profile:memberships:claimModal:ticketDetailsCTA') : t('profile:memberships:claimModal:billingDetailsCTA');

  return (
    <div className='payment-processed'>
      
      <Description>{description}</Description>
      
      {isTicket && 
        <Description preWrap>{ticketDescription}{amount > 1 && <Link onClick={handleRedirectToBillingDetails}>{t('literal:here')}</Link>}.</Description>}

      <div className='button-group'>

        <Button onClick={handleRedirect} fullwidth secondary>{backButtonLabel}</Button>

        {stripe_purchase && 
          <Button onClick={nextButtonAction} fullwidth>{nextButtonLabel}</Button>}

      </div>      
    </div>
  );
};

PaymentProcessed.propTypes = {
    setStep: PropTypes.func,
    handleClaimReset: PropTypes.func,
    stripe_purchase: PropTypes.bool,
    isTicket: PropTypes.bool,
    amount: PropTypes.number,
    atTheDoor: PropTypes.bool
};

export default PaymentProcessed;

