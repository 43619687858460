import React from "react";
import PropTypes from "prop-types";

import DateInputField from "components/DateInputField";

import FormSection from "ui/FormSection";
import Description from "ui/text/Description";
import Button from "ui/Button";
import isMobileDevice from "utils/mobileAndTabletCheck";

const Frequency = ({ event, onEventChange, setUnsavedChanges }) => {

    const handleRepeatDay = (value) => {
        setUnsavedChanges(true);

        if (repeat_days.includes(value)) {
            onEventChange({
                ...event,
                repeat_days: repeat_days.filter((day) => day !== value),
            });
        } else {
            onEventChange({
                ...event,
                repeat_days: [...repeat_days, value],
        });
        }
    };

    const {frequency_type = 'week', repeat_days, id} = event;

    const dateFields = [
        { attribute: 'start_date', label: 'Start Date *', dateOnly: true, disablePast: true },
        { attribute: 'end_date', label: 'End Date *', dateOnly: true, disablePast: true },
        { attribute: 'start_time', label: 'Start Time *', timeOnly: true },
        { attribute: 'end_time', label: 'End Time', timeOnly: true },
    ];

    const days = isMobileDevice() ? (
        ["S", "M", "T", "W", "T", "F", "S"]
    ) : (
        ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    );

  return (
    <FormSection title={"Frequency"}>

        <div className="date-fields">
            {dateFields.map((field, index) => (
                <DateInputField
                    key={index}
                    attribute={field.attribute}
                    label={field.label}
                    object={event}
                    onChange={onEventChange}
                    setUnsavedChanges={setUnsavedChanges}
                    disablePast={field.disablePast}
                    dateOnly={field.dateOnly}
                    timeOnly={field.timeOnly}
                    disabled={!!id}
                />))}
        </div>

        {frequency_type === "week" && (
            <>
                <Description>Repeat Weekly On:</Description>
                <div className="weekday-selector">
                    {days.map((day, index) => (
                        <Button
                            key={index}
                            rounded
                            secondary={!repeat_days.includes(index)}
                            onClick={() => handleRepeatDay(index)} >
                            {day}
                        </Button>
                    ))}
                </div>
            </>
        )}

    </FormSection>
  ) 
};

Frequency.propTypes = {
  event: PropTypes.object,
  onEventChange: PropTypes.func,
  setUnsavedChanges: PropTypes.func,
};

export default Frequency;
